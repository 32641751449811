<script lang="ts">
	import Formatted from "@components/common/Formatted.svelte";
	import Icon from "@components/common/Icon.svelte";
	import chevronNext from "@core/assets/icons/chevron.svg?raw";
	import type { LinkCardProps } from "./LinkCardProps.js";
	import Link from "./common/Link.svelte";

	const { iconName, title, desc, href, indexNumber, className }: LinkCardProps = $props();
</script>

<Link
	{href}
	class={[
		"bg-white text-left group mx-auto md:mx-0 max-sm:w-full no-underline max-w-sm p-3 sm:p-4 flex flex-col justify-between rounded-[1.25rem] gap-2 lg:gap-4 transition-all duration-300 cursor-pointer border-4 border-fstlight-100 hover:scale-105 sm:min-h-60 w-full",
		className,
	]}
>
	<div class="p-1 sm:p-4 flex flex-col items-start gap-2 sm:gap-4 max-w-72">
		{#if iconName}
			<Icon icon={iconName} class="text-fstgreen group-hover:text-fstphlox transition-colors duration-300"></Icon>
		{:else if indexNumber}
			<span class="text-fstgreen font-loos-wide text-lg">{indexNumber}</span>
		{/if}
		<h3 class="text-md md:text-lg font-bold text-fstonyx -tracking-[0.02em]">
			<Formatted text={title} />
		</h3>
		{#if desc}
			<p>{@render desc()}</p>
		{/if}
	</div>

	<div
		class="bg-fstlight-100 h-10 w-10 rounded-full flex justify-center self-end transition-colors group-hover:bg-fstgreen duration-300"
	>
		<Icon icon={chevronNext} class="text-fstgrey-200 self-center transition-colors group-hover:text-white" />
	</div>
</Link>
